<script>
import QRcode from "../../components/banktransfer/qrcode.vue";
import Banktransfer from "../../components/banktransfer/banktransfer.vue";

export default {
  name: 'GbpayQrcode',
  components: {Banktransfer, QRcode},
  beforeMount() {
    const query = this.$route.query
    if (query.token) {
      this.getData(query.token)
      this.token = query.token
    } else {
      this.$swal('Not have token', '', 'error')
    }
  },
  computed: {
    convertPrice() {
      return this.detail && this.detail.price && !isNaN(this.detail.price) ? this.detail.price.toFixed(2) : 0.00
    },
    messageComment() {
      return 'Please use mobile banking, scan the QRcode for payment.'
    },
  },
  sockets: {
    update_gbpay(data) {
      const query = this.$route.query
      if (query.token && data.token === query.token) {
        this.detail.status = data.status
        setTimeout(() => this.returnToPage(), 3000)
      }
    }
  },
  data() {
    return {
      token: '',
      loading: true,
      detail: {
        token: '',
        orderid: '',
        image_qrcode: '',
        status: 'wait',
        price: '',
        customer: '',
        return_page: ''
      }
    }
  },
  methods: {
    async getData(token) {
      this.loading = true
      try {
        const Result = await this.axios.get(`/paymentpage/qrcode/gbpay?token=${token}`)
        // console.log(Result.data);
        if (Result.data) {
          this.detail = Result.data
          this.detail.price = Result.data.amount
          this.detail.customer = Result.data.merchant_name
          this.loading = false
          setTimeout(() => this.returnToPage(), 3000)
        }
      } catch (e) {
        this.$swal('Error', 'error request api', 'error')
      }
    },
    returnToPage() {
      if (this.detail.status !== 'wait' && this.detail.return_page) {
        window.location.href = this.detail.return_page
      }
    },
  }
}
</script>

<template>
  <div>
    <b-container>
      <b-card header="Detail">
        <div v-if="loading">
          <div class="loader text-center"></div>
        </div>
        <div v-else>
          <div v-if="detail.status === 'wait'" class="text-center">
            <b-img-lazy :src="detail.image_qrcode" fluid></b-img-lazy>
            <p>ID: <strong>{{ detail.token }}</strong></p>
            <p>Merchant: <strong>{{ detail.customer }}</strong></p>
            <p>Order ID: <strong>{{ detail.orderid }}</strong></p>
            <p>Price: <strong>{{ convertPrice }} THB</strong></p>
          </div>
          <div v-else>
            <div v-if="detail.status === 'cancel'" class="swal2-icon swal2-error swal2-icon-show"
                 style="display: flex;">
              <span class="swal2-x-mark">
              <span class="swal2-x-mark-line-left"></span>
              <span class="swal2-x-mark-line-right"></span>
              </span>
            </div>
            <div v-if="detail.status === 'success'" class="swal2-icon swal2-success swal2-icon-show"
                 style="display: flex;">
              <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
              <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
              <div class="swal2-success-ring"></div>
              <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
              <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
            </div>
            <div class="text-center">
              <p>ID: <strong>{{ detail.token }}</strong></p>
              <p>Merchant: <strong>{{ detail.customer }}</strong></p>
              <p>Order ID: <strong>{{ detail.orderid }}</strong></p>
              <p>Price: <strong>{{ convertPrice }} THB</strong></p>
            </div>
          </div>
          <div v-if="detail.status === 'wait'" class="commentMessage">
            * {{ messageComment }}
          </div>
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<style scoped>

.commentMessage {
  color: red;
  text-align: center;
}

.loader {
  margin: auto;
  text-align: center;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>